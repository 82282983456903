import { useStaticQuery, graphql } from "gatsby";

export const useGravidanza = () => {
  const gravidanza = useStaticQuery(
    graphql`
      query Gravidanza {
        images: allFile(
          filter: { relativeDirectory: { eq: "corsi/gravidanza" } }
          sort: { fields: name }
        ) {
          edges {
            node {
              id
              childImageSharp {
                fluid(maxWidth: 300, maxHeight: 250, quality: 90) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    `
  );
  return gravidanza;
};
