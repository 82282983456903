import { useStaticQuery, graphql } from "gatsby";

export const usePillola = () => {
  const pillola = useStaticQuery(
    graphql`
      query Pillola {
        images: allFile(
          filter: { relativeDirectory: { eq: "corsi/pillola" } }
          sort: { fields: name }
        ) {
          edges {
            node {
              id
              childImageSharp {
                fluid(maxWidth: 300, maxHeight: 250, quality: 90) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    `
  );
  return pillola;
};
