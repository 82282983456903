import React from "react";
import styled from "styled-components";
import Card from "./Card";
import { usePillola } from "./corsi/pillola";
import { usePostparto } from "./corsi/postparto";
import { useGravidanza } from "./corsi/gravidanza";

const Courses = () => {
  const gravtitle = [
    "Consulenze e visite ostetriche",
    "Corso Preparto",
    "Massaggio Prenatale",
    "Pilates in Gravidanza",
    "Acquaticità in Gravidanza",
    "Belly Painting",
  ];
  const gravsubtitle = [
    "Assistenza in studio o a domicilio, dall’inizio alla fine della Gravidanza.",
    "Percorso di Accompagnamento alla Nascita di gruppo o privato. In studio, a domicilio e anche online.",
    "Un trattamento specifico per ogni trimestre di gestazione, prova tutti i benefici del massaggio.",
    "Corso di ginnastica dolce in Gravidanza a partire dalla 12 settimana di gestazione fino al parto. Anche online.",
    " Corso di movimento in acqua a partire dalla 16 settimana di gestazione fino al parto.",
    "Un’attività che coinvolge tutta la famiglia: coloriamo il pancione!",
  ];

  const posttitle = [
    "Consulenza per l'allattamento",
    "Massaggio neonatale",
    "Primo bagnetto con l'ostetrica",
    "Acquaticità neonatale",
    "Visite e consulenze in puerperio",
    "Corsi per i genitori dopo la nascita",
  ];
  const postsub = [
    "Affidati ad un professionista per allattare con serenità, riceverai il giusto sostegno. Anche a domicilio.",
    "Un’attività piacevole e rilassante da fare insieme al tuo bambino. Scopri tutti i benefici del Massaggio. Da 1 a 6 mesi di vita.",
    "Affidati ad un professionista per conoscere le pratiche più appropriate per l’igiene del tuo bambino",
    "Corso di Babynuoto. Attività in acqua con un genitore, per bambini da 6 a 36 mesi.",
    "Sostegno e supporto della donna e della nuova famiglia a domicilio, fin dal rientro a casa dopo il parto.",
    "Dallo Svezzamento, al Sonno del neonato, ai corsi di movimento per le mamme dopo il parto.",
  ];
  const pilltitle = [
    "Trattamento cicatrici della nascita",
    "Consulenze sulla Sessualità",
    "Consulenze sulla contraccezione",
  ];
  const stringa =
    "• Cicatrice da taglio cesareo\n• Lacerazioni del pavimento pelvico \n• Episiotomia. \n\nIl trattamento può essere eseguito anche dopo tanti anni dal parto";
  const pillsub = [
    stringa,
    "Consulenze di gruppo o private per bambini, adolescenti e adulti per scoprire il proprio corpo e la sessualità.",
    "Scopri quale metodo è più adatto a te, per proteggere la tua sessualità.",
  ];
  const gravidanza = useGravidanza();
  const postparto = usePostparto();
  const pillola = usePillola();
  return (
    <>
      <Subtitle id="gravidanza">Gravidanza</Subtitle>
      <Card images={gravidanza} title={gravtitle} sub={gravsubtitle} />
      <Subtitle id="nascita">Dopo La Nascita</Subtitle>
      <Card images={postparto} title={posttitle} sub={postsub} />
      <Subtitle id="salute">Salute e Sessualità</Subtitle>
      <Card images={pillola} title={pilltitle} sub={pillsub} />
    </>
  );
};

export default Courses;

const Subtitle = styled.h2`
  margin-top: 2vh;
  padding-left: 2vw;
  line-height: 100%;
  color: #03363d !important;
  font-size: 3rem !important;
  font-weight: 600 !important;
`;
