import { useStaticQuery, graphql } from "gatsby";

export const usePostparto = () => {
  const postparto = useStaticQuery(
    graphql`
      query Post {
        images: allFile(
          filter: { relativeDirectory: { eq: "corsi/postparto" } }
          sort: { fields: name }
        ) {
          edges {
            node {
              id
              childImageSharp {
                fluid(maxWidth: 300, maxHeight: 250, quality: 90) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    `
  );
  return postparto;
};
