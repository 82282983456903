import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Courses from "../components/courses";

const Corsi = () => (
  <Layout>
    <SEO title="Servizi" />
    <Courses />
  </Layout>
);

export default Corsi;
